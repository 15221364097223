import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'
import { currentEntityId } from 'Utils/functionUtils'

// state
const state = {
    bannerSliderList: []
}

// getters
const getters = {
    BANNER_SLIDER_LIST_DATA: state => {
        return state.bannerSliderList
    }
}

// actions
const actions = {
    GET_BANNER_OF_ENTITY_BY_TYPE: (context, payload) => {
        return new Promise((resolve, reject) => {
            commonRequest.getRequest(
                `/api/v1/portal/${currentEntityId()}/slider/get_banner_of_entity_by_type/`,
                payload,
                function (res) {
                    context.commit('GET_BANNER_OF_ENTITY_BY_TYPE_HANDLER', res)
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    }
}

// mutations
const mutations = {
    GET_BANNER_OF_ENTITY_BY_TYPE_HANDLER: (state, payload) => {
        state.bannerSliderList = payload
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
