import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'
import { currentEntityId } from 'Utils/functionUtils'

// state
const state = {
}

// getters
const getters = {
}

// actions
const actions = {
    CHECK_PROMOTION_CODE: (context, payload) => {
        return new Promise((resolve, reject) => {
            commonRequest.postRequest(
                `/api/v1/portal/${currentEntityId()}/promotion/code/voucher/product/verify/`,
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    }
}

// mutations
const mutations = {
}

export default {
    state,
    getters,
    actions,
    mutations
}
