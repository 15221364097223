import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'

// state
const state = {
}

// getters
const getters = {
}

// actions
const actions = {
  VERIFY_ACTIVE_KEY: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequestNoToken(
        `/api/v1/portal/user/verify_active_key/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  }
}

// mutations
const mutations = {
}

export default {
  state,
  getters,
  actions,
  mutations
}
