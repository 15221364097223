import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'
import { currentEntityId } from 'Utils/functionUtils'

// state
const state = {
    logoBgMenu: [],
    footersConfig: []
}

// getters
const getters = {
    LOGO_BG_MENU_DATA: state => {
        return state.logoBgMenu
    },
    FOOTERS_CONFIG_DATA: state => {
        return state.footersConfig
    }
}

// actions
const actions = {
    GET_LOGO_BG_MENU: (context, payload) => {
        return new Promise((resolve, reject) => {
            commonRequest.getRequest(
                `/api/v1/portal/${currentEntityId()}/layout/config/get_layout_config_with_key/`,
                payload,
                function (res) {
                    context.commit('GET_LOGO_BG_MENU_HANDLER', res)
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_FOOTERS_CONFIG: (context, payload) => {
        return new Promise((resolve, reject) => {
            commonRequest.getRequest(
                `/api/v1/portal/${currentEntityId()}/footer/config/`,
                payload,
                function (res) {
                    context.commit('GET_FOOTERS_CONFIG_HANDLER', res)
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    }
}

// mutations
const mutations = {
    GET_LOGO_BG_MENU_HANDLER: (state, payload) => {
        state.logoBgMenu = payload
    },
    GET_FOOTERS_CONFIG_HANDLER: (state, payload) => {
        let footersConfig = payload.results
        let page = {
            totalPage: payload.num_pages,
            currentPage: payload.page,
            pageSize: payload.page_size,
            perPage: payload.per_page
        }
        state.footersConfig = payload.results ? { results: footersConfig, paginate: page } : payload.data
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
