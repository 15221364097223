<template>
  <v-app id="app">
    <router-view />
  </v-app>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style lang="scss">
@import "@/styles/index.scss";
@font-face {
  font-family: Roboto;
  src: url('./assets/fonts/Roboto-Regular.ttf');
}
#app {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #F5F5F5;
}
</style>
