import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'
import { currentEntityId } from 'Utils/functionUtils'

// state
const state = {
    voucherProductList: [],
    carts: [],
    productCategoriesPublic: [],
    voucherOrderCartList: [],
    baoKimPaymentList: []
}

// getters
const getters = {
    VOUCHER_PRODUCT_LIST_DATA: state => {
        return state.voucherProductList
    },
    CARTS_DATA: state => {
        return state.carts
    },
    PRODUCT_CATEGORIES_PUBLIC_DATA: state => {
        return state.productCategoriesPublic
    },
    GET_VOUCHER_ORDER_CART_LIST_DATA: state => {
        return state.voucherOrderCartList
    },
    GET_BAO_KIM_PAYMENT_LIST_DATA: state => {
        return state.baoKimPaymentList
    }
}

// actions
const actions = {
    GET_PRODUCT_LIST: (context, payload) => {
        return new Promise((resolve, reject) => {
            commonRequest.getRequest(
                `/api/v1/portal/${currentEntityId()}/voucher/product/get_voucher_product_on_portal/`,
                payload,
                function (res) {
                    context.commit('GET_PRODUCT_LIST_HANDLER', res)
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_PRODUCT_CATEGORIES_LIST: (context, payload) => {
        return new Promise((resolve, reject) => {
            commonRequest.getRequest(
                `/api/v1/portal/${currentEntityId()}/category/voucher/product/get_categories_on_portal/`,
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_PRODUCT_DETAIL: (context, payload) => {
        return new Promise((resolve, reject) => {
            commonRequest.getRequest(
                `/api/v1/portal/${currentEntityId()}/voucher/product/${payload.id}/`,
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    ADD_PRODUCT_TO_CART: (context, payload) => {
        context.commit('ADD_PRODUCT_TO_CART_HANDLER', payload)
    },
    UPDATE_PRODUCT_QUANTITY: (context, payload) => {
        context.commit('UPDATE_PRODUCT_QUANTITY_HANDLER', payload)
    },
    REMOVE_PRODUCT_FROM_CART: (context, payload) => {
        context.commit('REMOVE_PRODUCT_FROM_CART_HANDLER', payload)
    },
    REMOVE_ALL_PRODUCT_FROM_CART: (context) => {
        context.commit('REMOVE_ALL_PRODUCT_FROM_CART_HANDLER')
    },
    ON_BUY_VOUCHER_PRODUCT: (context, payload) => {
        return new Promise((resolve, reject) => {
            commonRequest.postRequest(
                `/api/v1/portal/${currentEntityId()}/voucher/product/buy_product_from_portal/`,
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    ORDER_VOUCHER_PRODUCT: (context, payload) => {
        return new Promise((resolve, reject) => {
            commonRequest.postRequest(
                `/api/v1/portal/${currentEntityId()}/order/voucher/product/`,
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                },
                60000
            )
        })
    },
    GET_PRODUCT_ORDER: (context, payload) => {
        return new Promise((resolve, reject) => {
            commonRequest.getRequest(
                `/api/v1/portal/${currentEntityId()}/order/voucher/product/`,
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_PRODUCT_ORDER_DETAIL: (context, payload) => {
        return new Promise((resolve, reject) => {
            commonRequest.getRequest(
                `/api/v1/portal/${currentEntityId()}/order/voucher/product/get_order_product_detail/`,
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    CANCEL_VOUCHER_PRODUCT_ORDER: (context, payload) => {
        return new Promise((resolve, reject) => {
            commonRequest.postRequest(
                `/api/v1/portal/${currentEntityId()}/order/voucher/product/cancel_order_product/`,
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    PAYMENT_CONFIRMATION: (context, payload) => {
        return new Promise((resolve, reject) => {
            commonRequest.postRequest(
                `/api/v1/portal/${currentEntityId()}/voucher/product/payment_confirmation/`,
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_PRODUCT_CATEGORIES_PUBLIC: (context, payload) => {
        return new Promise((resolve, reject) => {
            commonRequest.getRequest(
                `/api/v1/portal/${currentEntityId()}/category/voucher/product/get_categories_public_on_portal/`,
                payload,
                function (res) {
                    context.commit('GET_PRODUCT_CATEGORIES_PUBLIC_HANDLER', res.data)
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_CREATE_ORDER_CODE_PREPARE: (context, payload) => {
        return new Promise((resolve, reject) => {
            commonRequest.postRequest(
                `/api/v1/portal/${currentEntityId()}/order/voucher/product/get_create_order_prepare/`,
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    ADD_VOUCHER_ORDER_CART: (context, payload) => {
        return new Promise((resolve, reject) => {
          commonRequest.postRequest(
            `/api/v1/${currentEntityId()}/voucher_order_cart/create_voucher_order_cart/`,
            payload,
            function (res) {
              resolve(res)
            },
            function (error) {
              reject(error)
            },
            60000
          )
        })
    },
    GET_VOUCHER_ORDER_CART_LIST: (context, payload) => {
        return new Promise((resolve, reject) => {
          commonRequest.getRequest(
            `/api/v1/${currentEntityId()}/voucher_order_cart/get_voucher_order_cart_list/`,
            payload,
            function (res) {
              context.commit('GET_VOUCHER_ORDER_CART_LIST', res.data)
              resolve(res)
            },
            function (error) {
              reject(error)
            }
          )
        })
    },
    DELETE_VOUCHER_ORDER_CART: (context, payload) => {
        return new Promise((resolve, reject) => {
          commonRequest.deleteRequest(
            `/api/v1/${currentEntityId()}/voucher_order_cart/${payload.id}/`,
            payload,
            function (res) {
              resolve(res)
            },
            function (error) {
              reject(error)
            }
          )
        })
    },
    UPDATE_VOUCHER_ORDER_CART: (context, payload) => {
        return new Promise((resolve, reject) => {
          commonRequest.putRequest(
            `/api/v1/${currentEntityId()}/voucher_order_cart/${payload.id}/update_quantity_voucher_in_cart/`,
            payload,
            function (res) {
              resolve(res)
            },
            function (error) {
              reject(error)
            }
          )
        })
    },
    GET_BAO_KIM_PAYMENT_LIST: (context, payload) => {
        return new Promise((resolve, reject) => {
          commonRequest.getRequest(
            `/api/v1/portal/${currentEntityId()}/bao_kim_payment/get_payment_list/`,
            payload,
            function (res) {
              context.commit('GET_BAO_KIM_PAYMENT_LIST', res.data)
              resolve(res)
            },
            function (error) {
              reject(error)
            }
          )
        })
    },
    ADD_BAO_KIM_PAYMENT: (context, payload) => {
        return new Promise((resolve, reject) => {
            commonRequest.postRequest(
            `/api/v1/portal/${currentEntityId()}/bao_kim_payment/`,
            payload,
            function (res) {
                resolve(res)
            },
            function (error) {
                reject(error)
            },
            60000
            )
        })
    },
    APPROVE_BAO_KIM_ORDER_PAYMENT: (context, payload) => {
        return new Promise((resolve, reject) => {
            commonRequest.postRequest(
            `/api/v1/portal/${currentEntityId()}/bao_kim_payment/approve_bao_kim_order_payment/`,
            payload,
            function (res) {
                resolve(res)
            },
            function (error) {
                reject(error)
            },
            60000
            )
        })
    }
}

// mutations
const mutations = {
    REMOVE_ALL_PRODUCT_FROM_CART_HANDLER: (state) => {
        state.carts = []
        sessionStorage.setItem('carts',  JSON.stringify(state.carts))
    },
    REMOVE_PRODUCT_FROM_CART_HANDLER: (state, payload) => {
        let cartList = sessionStorage.getItem('carts')
        cartList = JSON.parse(cartList)
        cartList.splice(payload, 1)
        state.carts = cartList
        sessionStorage.setItem('carts',  JSON.stringify(state.carts))
    },
    UPDATE_PRODUCT_QUANTITY_HANDLER: (state, payload) => {
        let cartList = sessionStorage.getItem('carts')
        cartList = JSON.parse(cartList)
        if (cartList && cartList.length !== 0) {
          state.carts = cartList
        }
        let isExist = false
        for (let i = 0, size = state.carts.length; i < size; i++) {
          if (state.carts[i].id === payload.id) {
            state.carts[i].quantity = payload.quantity
            isExist = true
          }
        }
        if (isExist) {
          sessionStorage.setItem('carts', JSON.stringify(state.carts))
        }
    },
    ADD_PRODUCT_TO_CART_HANDLER: (state, payload) => {
        let cartList = sessionStorage.getItem('carts')
        cartList = JSON.parse(cartList)
        if (cartList && cartList.length !== 0) {
            state.carts = cartList
        }
        let isExist = false
        for (let i = 0, size = state.carts.length; i < size; i++) {
            if (state.carts[i].id == payload.id) {
                state.carts[i].quantity += payload.quantity
                isExist = true
            }
        }
        if (!isExist) {
            state.carts.push(payload)
        }
        sessionStorage.setItem('carts', JSON.stringify(state.carts))
    },
    GET_PRODUCT_LIST_HANDLER: (state, payload) => {
        let voucherProductList = payload.results
        let page = {
            totalPage: payload.num_pages,
            currentPage: payload.page,
            pageSize: payload.page_size,
            perPage: payload.per_page
        }
        state.voucherProductList = payload.results ? { results: voucherProductList, paginate: page } : payload.data
    },
    GET_PRODUCT_CATEGORIES_PUBLIC_HANDLER: (state, payload) => {
        state.productCategoriesPublic = payload
    },
    GET_VOUCHER_ORDER_CART_LIST: (state, payload) => {
        let voucherOrderCartList = payload
        state.voucherOrderCartList = voucherOrderCartList
    },
    GET_BAO_KIM_PAYMENT_LIST: (state, payload) => {
        let baoKimPaymentList = payload
        state.baoKimPaymentList = baoKimPaymentList
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
