import PaymentMethodType from "Enum/paymentMethodType"
import jwtDecode from 'jwt-decode'

/**
 * Check null
 * @param {*} data
 */
const isNull = function (data) {
  if (data === undefined || data === null) {
    return true
  } else {
    return false
  }
}
/**
 * Check permission
 * @param {*} userInfo
 * @param {*} perm
 */
const hasAction = function (userInfo, perm) {
  let actions = userInfo.actions ? userInfo.actions : []
  if (isSuperAdmin(userInfo)) {
    return true
  }
  for (let i = 0; i < actions.length; i++) {
    let currentPerm = actions[i]
    if (currentPerm === perm) {
      return true
    }
  }
  return false
}
/**
 * Check is super user
 * @param {*} currentUser
 */
const isSuperAdmin = function (currentUser) {
  let userId = currentUser ? currentUser.id : null
  if (userId === 1) {
    return true
  } else {
    return false
  }
}
const decodeJwtResponse = function (data) {
  return !isEmptyString(data) ? jwtDecode(data) : null
}

/**
 * Get first name and last name from full name
 * @param {*} fullName
 */
const getFirstLastName = function (fullName) {
  let firstName = ''
  let lastName = ''
  if (fullName) {
    // Split full name to get last name and first name
    let fullNameArray = fullName.split(' ')
    firstName = fullNameArray[fullNameArray.length - 1]
    lastName = ''
    for (let i = 0, size = fullNameArray.length - 1; i < size; i++) {
      lastName += fullNameArray[i] + (i < size - 1 ? ' ' : '')
    }
  }
  return {
    'firstName': firstName,
    'lastName': lastName
  }
}
/**
 * Validate email
 * @param {*} email
 */
const validateEmail = function (email) {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}
/**
 * Has special character
 * @param {*} string
 */
const hasSpecialCharacter = function (string) {
  // eslint-disable-next-line no-useless-escape
  var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/
  if (format.test(string)) {
    return true
  } else {
    return false
  }
}
/**
 * Remove space in string
 * @param {*} str
 */
const removeSpaceInString = function (str) {
  return str.replace(/\s/g, '')
}
/**
 * Convert number to money
 * @param {*} number
 */
const convertFormatNumberToMoney = function (number) {
  return Number(number).toFixed(0).replace(/./g, function (c, i, a) {
    return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? '.' + c : c
  })
}
/**
 * Convert number money to normal number
 * @param {*} num
 */
const formatInteger = function (num) {
  num = !isNull(num) ? num : ''
  if (num.length > 3) {
    return num.toString().replace(/[^A-Z0-9]/ig, '')
  }
  return num
}
const validatePhone = function (phone) {
  const regex = /^(0[3|5|7|8|9][0-9]{8}|[+]84[3|5|7|8|9][0-9]{8})$/
  return !!regex.test(phone)
}
const validUsername = function (username) {
  var usernameRegex = /^[a-zA-Z0-9]+$/
  return username.match(usernameRegex)
}

/**
 * Check empty string
 * @param {*} value
 */
const isEmptyString = function (value) {
  return (value === undefined) || (value === null) || (typeof value === 'string' && value.trim().length === 0)
}

const validateStringIsNull = function (value) {
  if (value == null) {
    return true
  } else {
    return isEmptyString(value)
  }
}

const removeVietnameseTones = function (str) {
  str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  str = str.replace(/đ/g, 'd')
  str = str.replace(/Đ/g, 'D')
  // Remove extra spaces
  str = str.replace(/ + /g, ' ')
  str = str.trim()
  return str
}

const concatFullname = function (lastname, firstname) {
  if (!isEmptyString(firstname) && !isEmptyString(lastname)) {
    return lastname + ' ' + firstname
  } else if (!isEmptyString(firstname)) {
    return firstname
  } else if (!isEmptyString(lastname)) {
    return lastname
  }
  return ''
}

const concatFullAddress = function (addressInfo, street) {
  var fullAddress = street
  if (addressInfo.ward.type || addressInfo.ward.name) {
    if (!isEmptyString(fullAddress)) {
      fullAddress += ', ' + addressInfo.ward.type + ' ' + addressInfo.ward.name
    } else {
      fullAddress = addressInfo.ward.type + ' ' + addressInfo.ward.name
    }
  }
  if (addressInfo.district.type || addressInfo.district.name) {
    if (!isEmptyString(fullAddress)) {
      fullAddress += ', ' + addressInfo.district.type + ' ' + addressInfo.district.name
    } else {
      fullAddress = addressInfo.district.type + ' ' + addressInfo.district.name
    }
  }
  if (addressInfo.city.type || addressInfo.city.name) {
    if (!isEmptyString(fullAddress)) {
      fullAddress += ', ' + addressInfo.city.type + ' ' + addressInfo.city.name
    } else {
      fullAddress = addressInfo.city.type + ' ' + addressInfo.city.name
    }
  }
  if (!isEmptyString(fullAddress) && addressInfo.country.name) {
    fullAddress += ', ' + addressInfo.country.name
  }
  return fullAddress
}

const paymentMethodTypeName = function (paymentMethod) {
  if (!isNull(paymentMethod)) {
    paymentMethod = parseInt(paymentMethod)
    if (paymentMethod === PaymentMethodType.CASH_ON_DELIVERY) {
      return 'paymentMethod.type.cash'
    } else if (paymentMethod === PaymentMethodType.BANK_TRANSFER) {
      return 'paymentMethod.type.transfer'
    } else if (paymentMethod === PaymentMethodType.MOMO_WALLET) {
      return 'paymentMethod.type.momoWallet'
    } else if (paymentMethod === PaymentMethodType.ZALOPAY_WALLET) {
      return 'paymentMethod.type.zalopay'
    } else if (paymentMethod === PaymentMethodType.BAO_KIM_WALLET) {
      // return 'paymentMethod.type.baokim'
      return 'paymentMethod.type.onlinePayment'
    }
  }
  return ''
}

const ellipsisLongText = function (strText, len) {
  if (!isNull(strText) && strText.length > len) {
    const truncate = (str, len) => str.substring(0, (str + ' ').lastIndexOf(' ', len))
    return truncate(strText, len) + '...'
  }
  return strText
}

const checkQuantityProductCanAddInCart = function (cartList, productInfo, numberQuantityAdd) {
  let index = cartList.findIndex(x => x.id === productInfo.id)
  if (index !== -1) {
    if (cartList[index].quantity + numberQuantityAdd > productInfo.remainQuantity) {
      return false
    } else return true
  } else if (productInfo.remainQuantity < 1) {
    return false
  }
  return true
}

export const currentEntityId = function () {
  let entityId = sessionStorage.getItem('entityId')
  if (!isNull(entityId)) {
    return entityId
  }
  return 0
}


export default {
  isNull,
  hasAction,
  isSuperAdmin,
  decodeJwtResponse,
  getFirstLastName,
  validateEmail,
  hasSpecialCharacter,
  removeSpaceInString,
  convertFormatNumberToMoney,
  formatInteger,
  validatePhone,
  validUsername,
  validateStringIsNull,
  removeVietnameseTones,
  concatFullname,
  concatFullAddress,
  paymentMethodTypeName,
  ellipsisLongText,
  checkQuantityProductCanAddInCart
}
