import axios from 'axios'
import { isCookieEnabled, getCookie } from 'tiny-cookie'
import router from '../router/index'
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL
const getRequest = function (url, data, resolve, reject, timeout = 10000) {
  // 
  const currentRoute = router.currentRoute.fullPath 
  const reportStatisticPath = currentRoute === '/report-statistic'
  const token_name = reportStatisticPath ? 'report_statistic_token' : 'token'
  // 
  const config = {
    timeout: timeout,
    headers: {
      'Content-Type': 'application/json'
    }
  }
  const token = isCookieEnabled() ? getCookie(token_name) : ''
  if (token) {
    config.headers['Authorization'] = `JWT ${token}`
  }
  const instance = axios.create(config)
  instance
    .get(url, data)
    .then(res => {
      resolve(res)
    })
    .catch(error => {
      if (error.response?.status === 401) {
        if (reportStatisticPath) {
          router.push('/report-statistic-login')
        } else {
          router.push('/login')
        }
      }
      reject(error)
    })
}

const postRequest = function (url, data, resolve, reject, timeout = 10000) {
  // 
  const currentRoute = router.currentRoute.fullPath 
  const reportStatisticPath = currentRoute === '/report-statistic'
  const token_name = reportStatisticPath ? 'report_statistic_token' : 'token'
  // 
  const config = {
    timeout: timeout,
    headers: {
      'Content-Type': 'application/json'
    }
  }
  const token = isCookieEnabled() ? getCookie(token_name) : ''
  if (token) {
    config.headers['Authorization'] = `JWT ${token}`
  }
  const instance = axios.create(config)
  instance
    .post(url, data)
    .then(res => {
      resolve(res)
    })
    .catch(error => {
      if (error.response?.status === 401) {
        if (reportStatisticPath) {
          router.push('/report-statistic-login')
        }else {
          router.push('/login')
        }
      }
      reject(error)
    })
}

const postRequestNoToken = function (url, data, resolve, reject, timeout = 10000) {
  const config = {
    timeout: timeout,
    headers: {
      'Content-Type': 'application/json'
    }
  }
  const instance = axios.create(config)
  instance
    .post(url, data)
    .then(res => {
      resolve(res)
    })
    .catch(error => {
      reject(error)
    })
}

const deleteRequest = function (url, data, resolve, reject, timeout = 10000) {
  const config = {
    timeout: timeout,
    headers: {
      'Content-Type': 'application/json'
    }
  }
  const token = isCookieEnabled() ? getCookie('token') : ''
  if (token) {
    config.headers['Authorization'] = `JWT ${token}`
  }
  const instance = axios.create(config)
  instance
    .delete(url, data)
    .then(res => {
      resolve(res)
    })
    .catch(error => {
      if (error.response?.status === 401) {
        router.push('/login')
      }
      reject(error)
    })
}

const putRequest = function (url, data, resolve, reject, timeout = 10000) {
  const config = {
    timeout: timeout,
    headers: {
      'Content-Type': 'application/json'
    }
  }
  const token = isCookieEnabled() ? getCookie('token') : ''
  if (token) {
    config.headers['Authorization'] = `JWT ${token}`
  }
  const instance = axios.create(config)
  instance
    .put(url, data)
    .then(res => {
      resolve(res)
    })
    .catch(error => {
      if (error.response?.status === 401) {
        router.push('/login')
      }
      reject(error)
    })
}

export default {
  getRequest,
  postRequest,
  postRequestNoToken,
  deleteRequest,
  putRequest
}
