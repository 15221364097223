import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'
import { currentEntityId } from 'Utils/functionUtils'
import {
    isCookieEnabled,
    setCookie,
    removeCookie
} from 'tiny-cookie'
// state
const state = {
}

// getters
const getters = {
}

// actions
const actions = {
    VERIFY_ENTITY_CODE: (context, payload) => {
        return new Promise((resolve, reject) => {
            commonRequest.postRequest(
                `/api/v1/portal/user/verify_entity_code/`,
                payload,
                function (res) {
                    let data = res.data.data
                    sessionStorage.setItem('entityId', data.entity_id)
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    ENTITY_LOGIN: (context, payload) => {
        return new Promise((resolve, reject) => {
            commonRequest.postRequest(
                `/api/v1/portal/${currentEntityId()}/authen/entity_login/`,
                payload,
                function (res) {
                    let token = res.data.response.token
                    if (isCookieEnabled()) {
                        setCookie('token', token)
                    }
                    resolve(res)
                },
                function (error) {
                    if (isCookieEnabled()) {
                        removeCookie('token')
                    }
                    reject(error)
                }
            )
        })
    },
    LOGIN_WITH_GOOGLE: (context, payload) => {
        return new Promise((resolve, reject) => {
            commonRequest.postRequest(
                `api/v1/social_network_login/login_with_google/`,
                payload,
                function (res) {
                    if (res.data.response !== undefined) {
                        let userId = res.data.response.user_id;
                        if (isCookieEnabled()) {
                            setCookie('token', res.data.response.token)
                            setCookie("userId", userId);
                            sessionStorage.setItem('isLoginSocial', true)
                        }
                    }
                    resolve(res)
                },
                function (error) {
                    if (isCookieEnabled()) {
                        removeCookie('token')
                    }
                    reject(error)
                }
            )
        })
    },
    SOCIAL_NETWORK_LOGIN: (context, payload) => {
        return new Promise((resolve, reject) => {
            commonRequest.postRequest(
                `api/v1/social_network/`,
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_SOCIAL_NETWORK: (context, payload) => {
        return new Promise((resolve, reject) => {
            commonRequest.getRequest(
                `api/v1/social_network/`,
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    REGISTER_WITH_GOOGLE: (context, payload) => {
        return new Promise((resolve, reject) => {
            commonRequest.postRequest(
                `api/v1/social_network_login/register_with_google/`,
                payload,
                function (res) {
                    let userId = res.data.response.user_id;
                    if (isCookieEnabled()) {
                        setCookie('token', res.data.response.token)
                        setCookie("userId", userId);
                        sessionStorage.setItem('isLoginSocial', true)
                    }
                    context.commit('LOGIN_USER_HANDLER', res.data)
                    resolve(res)
                },
                function (error) {
                    if (isCookieEnabled()) {
                        removeCookie('token')
                    }
                    reject(error)
                }
            )
        })
    }
}

// mutations
const mutations = {
}

export default {
    state,
    getters,
    actions,
    mutations
}
