/**
 * Vuex
 *
 * @library
 *
 * https://vuex.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Vuex from 'vuex'

// Store functionality
// import modules from './modules'
// Functions
import users from './modules/users'
import activeKey from './modules/activeKey'
import product from './modules/product'
import banner from './modules/banner'
import layoutConfig from './modules/layoutConfig'
import entity from './modules/entity'
import locale from './modules/locale'
import promotion from './modules/promotion'
import payments from  './modules/payments'
import ad from  './modules/ad'
import report from  './modules/report'
Vue.use(Vuex)

// Create a new store
const store = new Vuex.Store({
  modules: {
    users,
    activeKey,
    product,
    banner,
    layoutConfig,
    entity,
    locale,
    promotion,
    payments,
    ad,
    report
  }
})

export default store
