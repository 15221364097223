export default {
    'common': {
        'logout': 'Đăng xuất',
        'actions': 'Công cụ',
        'buyNow': 'Mua ngay',
        'goToPage': 'Đến trang',
        'login': 'Đăng nhập',
        'createAccount': 'Tạo tài khoản',
        'email': 'Email',
        'password': 'Mật khẩu',
        'success': 'Thành công !',
        'somethingWentWrong': 'Có lỗi xảy ra !',
        'continue': 'Tiếp tục',
        'register': 'Đăng ký',
        'welcomeToConasi': 'Chào mừng bạn đến với trang bán hàng dành cho đối tác của Conasi',
        'customer': 'Khách hàng',
        'address': 'Địa chỉ',
        'change': 'Thay đổi',
        'fullname': 'Họ tên',
        'back': 'Quay lại',
        'copyToClipboard': 'Sao chép',
        'copied': 'Đã sao chép',
        'delete': 'Xoá',
        'year': 'Năm',
        'loginWithGoogle': 'Đăng nhập với Google'
    },
    'menu': {
      'home': 'Trang chủ',
      'about': 'Về chúng tôi',
      'product': 'Sản phẩm',
      'news': 'Tin tức',
      'contact': 'Liên hệ',
      'support': 'Hỗ trợ',
      'search': 'Tìm kiếm',
      'cart': 'Giỏ hàng',
      'profile': 'Hồ sơ người dùng'
    },
    'home': {
        'product': {
            'latest': 'Sản phẩm mới nhất',
            'bestSeller': 'Sản phẩm bán chạy'
        },
        'news': {
            'title': 'Tin tức',
            'readMore': 'Đọc thêm'
        }
    },
    'productOrder': {
        'myOrderTitle': 'Đơn hàng của tôi',
        'tableList': {
            'header': {
                'orderCode': 'Mã đơn hàng',
                'purchaseDate': 'Ngày mua',
                'product': 'Sản phẩm',
                'totalPrice': 'Tổng tiền',
                'status': 'Trạng thái đơn'
            },
            'status': {
                'paid': 'Đã thanh toán',
                'waitForPay': 'Chờ thanh toán',
                'cancel': 'Thanh toán đã bị huỷ',
                'delivered': 'Đã phân phối'
            }
        },
        'detail': {
            'price': 'Giá tiền',
            'quantity': 'Số lượng',
            'order': 'Đặt hàng',
            'detail': 'chi tiết',
            'image': 'Hình ảnh'
        },
        'promotion': {
            'hasExpired': 'Mã khuyến mãi đã hết số lần sử dụng',
            'notExist': 'Mã khuyến mãi không tồn tại',
            'code': 'Mã giảm giá',
            'apply': 'Áp dụng',
            'discount': 'Giảm giá',
            'totalPriceToPay': 'Tổng tiền cần thanh toán',
            'expiredPromotionCode': 'Mã giảm giá hết hạn'
        },
        'haveRunOutOfPurchasesThisProduct': 'Bạn đã hết số lần được mua hàng cho sản phẩm này',
        'infoAboutCustomersOrderingVouchers': 'Thông tin khách hàng đặt voucher',
        'voucherRecipientInfo': 'Thông tin người nhận voucher',
        'outOfStock': 'Hết hàng'
    },
    'product': {
        'list': 'Danh sách sản phẩm',
        'category': {
            'list': 'Danh mục sản phẩm',
            'all': 'Tất cả'
        },
        'detail': {
            'buy': 'Mua hàng',
            'productInfo': 'Thông tin sản phẩm',
            'info': 'Thông tin',
            'conditionsOfUse': 'Điều kiện sử dụng',
            'locationOfUse': 'Địa điểm sử dụng',
            'extraInfo': 'Thông tin thêm',
            'remainItems': 'Còn {count} sản phẩm'
        },
        'similar': 'Sản phẩm tương tự',
        'featured': 'Sản phẩm nổi bật',
        'enterInfo': 'Nhập thông tin sản phẩm',
        'filter': {
            'advanceSearch': 'Tìm kiếm nâng cao',
            'province': 'Tỉnh / Thành phố',
            'district': 'Quận / Huyện',
            'ward': 'Xã / Phường',
            'search': 'Tìm kiếm',
            'inOrderOfPrice': 'Theo thứ tự giá',
            'type': {
                'default': 'Thứ tự mặc định',
                'highToLow': 'Theo thứ tự giá: Cao xuống thấp',
                'lowToHigh': 'Theo thứ tự giá: Thấp tới cao'
            },
            'title': 'Lọc'
        }
    },
    'cart': {
        'title': 'Giỏ hàng',
        'orderSummary': 'Tóm tắt đơn hàng',
        'noProductInCart': 'Không có sản phẩm trong giỏ hàng !',
        'totalPrice': 'Tổng giá trước thuế',
        'fee': 'Thuế',
        'totalPriceWithFee': 'Tổng giá sau thuế',
        'orderConfirmation': 'Xác nhận',
        'confirmPayment': 'Xác nhận thanh toán',
        'cancelPayment': 'Hủy',
        'successfullyOrderedVoucher': 'Đặt voucher thành công !',
        'successfullyDeletedVoucher': 'Xoá thành công voucher',
        'outOfCart': 'ra khỏi giỏ hàng',
        'noProductsInCart': 'Không có sản phẩm nào trong giỏ hàng !',
        'voucherInfoSentViaEmail': 'Thông tin voucher được gửi qua email',
        'makeSureEmailIsEnteredCorrectly': 'Hãy chắc chắn rằng email đã nhập đúng',
        'provisional': 'Tạm tính',
        'chooseProduct': 'Chọn sản phẩm' ,
        'shipmentDetails': 'Thông tin giao hàng',
        'payment': 'Thanh toán',
        'quantityMustGreatThanZero': 'Số lượng sản phẩm phải lớn hơn 0',
        'addToCartSuccessed': 'Thêm vào giỏ hàng thành công',
        'addToCartOverQuantity': 'Số lượng voucher muốn mua nhiều hơn số voucher hiện có trong cửa hàng',
        'paymentAmount': 'Số tiền cần thanh toán',
        'productCanBuyTimes': 'Sản phẩm còn {0} lượt mua',
        'validProductOnCart': 'Sản phẩm \'{0}\' còn {1} lượt mua',
        'productOutOfStock': 'Sản phẩm \'{0}\' đã hết hàng',
        'promotionCodeAlreadyUsed': 'Mã giảm giá đã được sử dụng'
    },
    'confirmActiveKey': {
        'welcomeTo': 'Chào mừng đến với' ,
        'onlineShop': 'Cửa hàng trực tuyến dành cho đối tác của',
        'toContinue': 'Để tiếp tục, vui lòng điền mã xác thực đã được cung cấp vào ô bên dưới',
        'verificationCodes': 'Mã xác thực',
        'continue': 'Tiếp tục',
        'codeIssuedByTheAgent': 'Mã xác thực được cấp bởi đại lý',
        'enterVerificationCode': 'Vui lòng nhập mã xác thực',
        'codeNotExist': 'Mã xác thực không tồn tại',
        'verificationCodeHasExpired': 'Mã xác thực đã quá hạn sử dụng'
    },
    'intro': {
        'haveAccountPleaseLogin': 'Nếu bạn đã có tài khoản, vui lòng đăng nhập',
        'or': 'Hoặc'
    },
    'login': {
        'signInYourAccount': 'Đăng nhập tài khoản Conasi 365 của bạn',
        'thisFieldIsRequired': 'Trường này là bắt buộc',
        'emailMustBeValid': 'Vui lòng nhập đúng định dạng email',
        'youHaveNotRegister': 'Bạn chưa đăng ký, vui lòng đăng ký!',
        'inCorrectPassword': 'Mật khẩu không đúng ',
        'accountIsNotActive': 'Tài khoản chưa được kích hoạt',
        'userIsDeleted': 'Tài khoản đã bị xóa',
        'accountNotRoleReport': 'Vui lòng đăng nhập với tài khoản người báo cáo',
        'somethingWentWrong': 'Có Lỗi Xảy Ra !',
        'forgotPassword': "Quên mật khẩu?"
    },
    'forgotPassword': {
        'passwordRetrieval': 'Lấy lại mật khẩu',
        'enterEmail': 'Nhập email',
        'send': 'Gửi',
        'pleaseCheckEmailToChangePassword': 'Vui lòng kiểm tra email để lấy lại mật khẩu !',
        'back': 'Trở lại đăng nhập',
        'passwordIncorrect': 'Mật khẩu không khớp'
    },
    'confirmEmailDomain': {
        'toContinueEnterYourEmailDomain': 'Để tiếp tục, vui lòng điền email domain vào ô bên dưới',
        'emailDomain': 'Tên miền email',
        'emailDomainToLink': 'Tên miền email cần liên kết',
        'successfulWaitForLinkConfirm': 'Vui kiểm tra email và xác nhận liên kết !',
        'emailDomainNotExist': 'Email domain không tồn tại'
    },
    'register': {
        'passNotMatch': 'Mật khẩu không khớp',
        'signUpYourAccount': 'Tạo tài khoản Conasi 365 của bạn',
        'confirmPass': 'Xác nhận mật khẩu',
        'fullName': 'Họ và tên',
        'alreadyHaveAnAccount': 'Đã có tài khoản?',
        'registerWithGoogle': 'Đăng ký với google',
        'notiRegistered': {
            'noti_1': 'Bạn vừa được tạo thành công tài khoản trên ứng dụng của chúng tôi.',
            'noti_2': 'Vui lòng kiềm tra email (hộp thư hoặc trong thư rác) và xác nhận kích hoạt tài khoản trước khi sử dụng.',
            'noti_3': 'Không nhận được email kích hoạt, nhấn vào đây để',
            'noti_4': 'Quay lại trang'
        },
        'resendActivationEmail': 'Gởi lại thông tin kích hoạt'
    },
    'confirmEntityCode': {
        'toContinue': 'Để tiếp tục, vui lòng điền mã xác thực đã được cung cấp vào ô bên dưới',
        'agentCode': 'Mã đại lý',
        'codeForEachAgent': 'Mã giành cho mỗi đại lý',
        'agentCodeDoesNotExist': 'Mã đại lý không tồn tại'
    },
    'adminLogin': {
        'logInYourAgentAccount': 'Đăng nhập tài khoản quản lý đại lý của bạn'
    },
    'productOrderManagement': {
        'listTitle': 'Danh sách đơn hàng'
    },
    'confirmModal': {
        'confirmCancelOrder': 'Bạn có chắc chắn muốn huỷ đơn hàng này?',
        'title': 'Xác nhận',
        'btn': {
            'confirm': 'Xác nhận',
            'close': 'Đóng'
        }
    },
    'navigation': {
        'myAccount': 'Tài khoản của tôi',
        'myOrder': 'Đơn hàng của tôi',
        'account': 'Tài khoản'
    },
    'profile': {
        'info': 'Thông tin tài khoản',
        'phone': 'Số điện thoại',
        'btn': {
            'update': 'Cập nhật',
            'changePass':  'Đổi mật khẩu'
        },
        'changePass': {
            'title': 'Đổi mật khẩu',
            'oldPass': 'Mật khẩu cũ',
            'newPass': 'Mật khẩu mới',
            'confirmPass': 'Xác nhận mật khẩu',
            'passConfirmNotMatch': 'Mật khẩu xác nhận không khớp.',
            'odlPassNotCorrect': 'Mật khẩu cũ không chính xác !'
        }
    },
    'paymentMethod': {
        'title': 'Phương thức thanh toán',
        'choose': 'Chọn hình thức thanh toán',
        'transferContent': 'Nội dung chuyển khoản',
        'accountNumber': 'Số tài khoản',
        'accountHolder': 'Chủ tài khoản',
        'branchBank': 'Chi nhánh',
        'type': {
            'cash': 'Trả tiền mặt khi nhận hàng',
            'transfer': 'Chuyển khoản ngân hàng',
            'onlinePayment': 'Thanh toán trực tuyến',
            'momoWallet': 'Thanh toán bằng ví MoMo',
            'zalopay': 'Thanh toán bằng ví ZaloPay',
            'baokim': 'Bảo Kim'
        },
        'intro': {
            'step': 'Bước {idx}',
            'instructions': 'Hướng dẫn thanh toán',
            'scanQR': 'Quét mã QR để thanh toán',
            'openApp': 'Mở ứng dụng {appname} trên điện thoại',
            'scanning': 'Trên {appname}, chọn biểu tượng quét mã',
            'scanToPay': 'Quét mã QR ở đây và thanh toán',
            'paymentTimeout': 'Giao dịch sẽ kết thúc sau'
        },
        'applicableCode': 'Mã áp dụng',
        'discountOnBill': 'Giảm giá trên hóa đơn',
        'discountOnVoucher': 'Giảm giá trên voucher'
    },
    'reportStatistic': {
        'title': 'Báo Cáo Thông Kê Năm {0}',
        'numberUserLastLogin': 'Số Lượng Người Truy Cập',
        'numberEntity': 'Số Người Bán',
        'numberNewEntity': 'Số Người Bán Mới',
        'totalProduct': 'Tổng Số Sản Phẩm',
        'totalNewProduct': 'Số Sản Phẩm Mới',
        'totalProductOrder': 'Số Lượng Giao Dịch',
        'totalProductOrderComplete': 'Tổng Số Đơn Hàng Thành Công',
        'totalProductOrderCancel': 'Tổng Số Đơn Hàng Không Thành Công',
        'totalFeeOrder': 'Tổng Giá Trị Giao Dịch'
    },
    'baoKimConfig': {
        'baoKimPaymentMethodType': 'Loại Phương Thức Thanh Toán',
        'baoKimPaymentMethod': 'Phương Thức Thanh Toán',
        'paymentMethod': {
          'BAO_KIM_WALLET': 'Ví Bảo Kim',
          'ATM_CARD_ONLINE': 'Thẻ ATM',
          'VISA_MASTER_CARD': 'Thẻ Visa/Master',
          'TRANSFER': 'Chuyển Khoản',
          'QR_CODE': 'Mã QR',
          'VIRTUAL_ACCOUNT': 'Tài Khoản Ảo'
        },
        'paymentConfirm': {
            'completed': 'Đơn hàng của bạn được thanh toán thành công',
            'errorPaymentConfirm': 'Đơn hàng của bạn chưa thanh toán'
        }
    },
    'user': {
        'successfulActivation': 'Kích hoạt thành công',
        'failedActivation': 'Kích hoạt thất bại, vui lòng thử lại sau',
        'redirectLoginPage': 'Quay về trang đăng nhập sau {0} giây nữa',
        'resetPassword': {
            'linkNoLongerValid': 'Đường dẫn xác nhận không còn tồn tại.'
        }
    },
    'socialNetwork': {
        'notiAddSocialAccountLink': 'Bạn có chắc chắn muốn thêm liên kết tài khoản {0}?',
        'googleAccountLinkExists': 'Tài khoản google đã được liên kết với tài khoản khác',
        'facebookAccountLinkExists': 'Tài khoản facebook đã được liên kết với tài khoản khác',
        'googleAccountHasBeenRegistered': 'Tài khoản google đã được đăng ký',
        'notiConfirmCreateAccountWithGoogle': 'Bạn có chắc chắn muốn tạo tài khoản với thông tin tài khoản google này?'
    }
}