export default {
    'common': {
        'logout': 'Logout',
        'actions': 'Actions',
        'buyNow': 'Buy now',
        'goToPage': 'Go to page',
        'login': 'Login',
        'createAccount': 'Create account',
        'email': 'Email',
        'password': 'Password',
        'success': 'Success !',
        'somethingWentWrong': 'Something went wrong !',
        'continue': 'Continue',
        'register': 'Register',
        'welcomeToConasi': 'Welcome to Conasi partner sales page',
        'customer': 'Customer',
        'address': 'Address',
        'change': 'Change',
        'fullname': 'Full name',
        'back': 'Back',
        'copyToClipboard': 'Copy',
        'copied': 'Copied',
        'delete': 'Delete',
        'year': 'Year',
        'loginWithGoogle': 'Login with Google'
    },
    'menu': {
        'home': 'Home',
        'about': 'About',
        'product': 'Products',
        'news': 'News',
        'contact': 'Contact',
        'support': 'Support',
        'search': 'Search',
        'cart': 'Cart',
        'profile': 'Profile'
    },
    'home': {
        'product': {
            'latest': 'Latest product',
            'bestSeller': 'Selling products'
        },
        'news': {
            'title': 'News',
            'readMore': 'Read more'
        }
    },
    'productOrder': {
        'myOrderTitle': 'My orders',
        'tableList': {
            'header': {
                'orderCode': 'Order code',
                'purchaseDate': 'Purchase date',
                'product': 'Product',
                'totalPrice': 'Total price',
                'status': 'Status'
            },
            'status': {
                'paid': 'Paid',
                'waitForPay': 'Wait for pay',
                'cancel': 'Cancel',
                'delivered': 'Delivered'
            }
        },
        'detail': {
            'price': 'Price',
            'quantity': 'Quantity',
            'order': 'Order',
            'detail': 'detail',
            'image': 'Image'
        },
        'promotion': {
            'hasExpired': 'Promo code has not enough usage times',
            'notExist': 'Promo code not exists',
            'code': 'Promo code',
            'apply': 'Apply',
            'discount': 'Discount',
            'totalPriceToPay': 'Total price to pay',
            'expiredPromotionCode': 'Expired promotion code'
        },
        'haveRunOutOfPurchasesThisProduct': 'You have run out of purchases for this product',
        'infoAboutCustomersOrderingVouchers': 'Information about customers ordering vouchers',
        'voucherRecipientInfo': 'Voucher recipient information',
        'outOfStock': 'Out of stock'
    },
    'product': {
        'list': 'Products list',
        'category': {
            'list': 'Categories list',
            'all': 'All'
        },
        'detail': {
            'buy': 'Buy',
            'productInfo': 'Product information',
            'info': 'Information',
            'conditionsOfUse': 'Conditions of use',
            'locationOfUse': 'Location of use',
            'extraInfo': 'More information',
            'remainItems': 'Remain {count} voucher(s)'
        },
        'similar': 'Similar products',
        'featured': 'Featured products',
        'enterInfo': 'Enter product information',
        'filter': {
            'advanceSearch': 'Advanced search',
            'province': 'City / Province',
            'district': 'District',
            'ward': 'Ward',
            'search': 'Search',
            'inOrderOfPrice': 'In order of price',
            'type': {
                'default': 'Default order',
                'highToLow': 'In order of price: High to low',
                'lowToHigh': 'In order of price: Low to high'
            },
            'title': 'Filter'
        }
    },
    'cart': {
        'title': 'Cart',
        'orderSummary': 'Order summary',
        'noProductInCart': 'There are no products in the cart! ',
        'totalPrice': 'Total price',
        'fee': 'Fee',
        'totalPriceWithFee': 'Total price with fee',
        'orderConfirmation': 'Order',
        'confirmPayment': 'Payment confirmation',
        'cancelPayment': 'Cancel',
        'successfullyOrderedVoucher': 'Successfully ordered voucher !',
        'successfullyDeletedVoucher': 'Successfully deleted voucher',
        'outOfCart': 'out of cart',
        'noProductsInCart': 'There are no products in the shopping cart !',
        'voucherInfoSentViaEmail': 'Voucher information sent via email',
        'makeSureEmailIsEnteredCorrectly': 'Please make sure the email is entered correctly',
        'provisional': 'Provisional price',
        'chooseProduct': 'Choose product' ,
        'shipmentDetails': 'Shipment details',
        'payment': 'Payment',
        'quantityMustGreatThanZero': 'Number of voucher must be greater 0',
        'addToCartSuccessed': 'Add to cart successful',
        'addToCartOverQuantity': 'Reached maximum voucher quantity in store',
        'paymentAmount': 'Payment amount',
        'productCanBuyTimes': 'Product can buy {0} times',
        'validProductOnCart': 'Product \'{0}\' can buy {1} times',
        'productOutOfStock': 'Product \'{0}\' is out of stock',
        'promotionCodeAlreadyUsed': 'Promotion code already used'
    },
    'confirmActiveKey': {
        'welcomeTo': 'Welcome to' ,
        'onlineShop': 'Online shop for partners of',
        'toContinue': 'To continue, please enter the verification code provided in the box below',
        'verificationCodes': 'Verification codes',
        'continue': 'Continue',
        'codeIssuedByTheAgent': 'Authentication code issued by the agent',
        'enterVerificationCode': 'Please enter the verification code',
        'codeNotExist': 'Verification code does not exist',
        'verificationCodeHasExpired': 'Verification code has expired'
    },
    'intro': {
        'haveAccountPleaseLogin': 'If you already have an account, please login',
        'or': 'Or'
    },
    'login': {
        'signInYourAccount': 'Sign in to your Conasi 365 account',
        'thisFieldIsRequired': 'This field is required',
        'emailMustBeValid': 'Email must be valid',
        'youHaveNotRegister': 'You have not registered, please register!',
        'inCorrectPassword': 'Incorrect password',
        'accountIsNotActive': 'User is not active',
        'userIsDeleted': 'User is deleted',
        'accountNotRoleReport': 'Please login with a reporter account',
        'somethingWentWrong': 'Something Went Wrong !',
        'forgotPassword': 'Forgot Password?'
    },
    'forgotPassword': {
        'passwordRetrieval': 'Password Retrieval',
        'enterEmail': 'Enter email',
        'send': 'Send',
        'pleaseCheckEmailToChangePassword': 'Please check email to retrieve password !',
        'back': 'Back to login',
        'passwordIncorrect': 'Password incorrect'
    },
    'confirmEmailDomain': {
        'toContinueEnterYourEmailDomain': 'To continue, please enter your email domain in the box below',
        'emailDomain': 'Email domain',
        'emailDomainToLink': 'Email domain to link',
        'successfulWaitForLinkConfirm': 'Please check email and confirm activation!',
        'emailDomainNotExist': 'Email domain does not exist'
    },
    'register': {
        'passNotMatch': 'Password not match',
        'signUpYourAccount': 'Create your Conasi 365 account',
        'confirmPass': 'Confirm password',
        'fullName': 'User name',
        'alreadyHaveAnAccount': 'Already have an account?',
        'registerWithGoogle': 'Register with google',
        'notiRegistered': {
            'noti_1': 'You have just successfully created an account on our application.',
            'noti_2': 'Please check your email (inbox or spam) and then confirm activation account before using.',
            'noti_3': 'If your do not receive activation email, press her to',
            'noti_4': 'Back to'
        },
        'resendActivationEmail': 'Resend activation email'
    },
    'confirmEntityCode': {
        'toContinue': 'To continue, please enter the agent code provided in the box below',
        'agentCode': 'Agent code',
        'codeForEachAgent': 'Code for each agent',
        'agentCodeDoesNotExist': 'Agent code does not exist'
    },
    'adminLogin': {
        'logInYourAgentAccount': 'Log in to your agent management account'
    },
    'productOrderManagement': {
        'listTitle': 'Product orders list'
    },
    'confirmModal': {
        'confirmCancelOrder': 'Are you sure you want to cancel this order?',
        'title': 'Confirm',
        'btn': {
            'confirm': 'Confirm',
            'close': 'Close'
        }
    },
    'navigation': {
        'myAccount': 'My account',
        'myOrder': 'My product orders',
        'account': 'Account'
    },
    'profile': {
        'info': 'Profile information',
        'phone': 'Phone',
        'btn': {
            'update': 'Update',
            'changePass':  'Change password'
        },
        'changePass': {
            'title': 'Change password',
            'oldPass': 'Old password',
            'newPass': 'New password',
            'confirmPass': 'Confirm password',
            'passConfirmNotMatch': 'The password confirmation does not match.',
            'odlPassNotCorrect': 'Old password is not correct !'
        }
    },
    'paymentMethod': {
        'title': 'Payment method',
        'choose': 'Choose payment method',
        'transferContent': 'Transfer content',
        'accountNumber': 'Account number',
        'accountHolder': 'Account holder',
        'branchBank': 'Branch',
        'type': {
            'cash': 'Cash on delivery',
            'transfer': 'Bank transfer',
            'onlinePayment': 'Online Payment',
            'momoWallet': 'Pay with MoMo wallet',
            'zalopay': 'Pay with ZaloPay',
            'baokim': 'Pay with Bao Kim'
        },
        'intro': {
            'step': 'Step {idx}',
            'instructions': 'Payment instructions',
            'scanQR': 'Scan QR code for payment',
            'openApp': 'Open {appname} application on mobile phone',
            'scanning': 'On {appname}, choose icon scan code',
            'scanToPay': 'Scan QR here and process payment',
            'paymentTimeout': 'Transaction will be end in'
        },
        'applicableCode': 'Applicable code',
        'discountOnBill': 'Discount on bill',
        'discountOnVoucher': 'Discount on voucher'
    },
    'reportStatistic': {
        'title': 'Báo Cáo Thông Kê Năm {0}',
        'numberUserLastLogin': 'Number User Last Login',
        'numberEntity': 'Number of Sellers',
        'numberNewEntity': 'Number of New Sellers',
        'totalProduct': 'Total Products',
        'totalNewProduct': 'New Product Number',
        'totalProductOrder': 'Number of Transactions',
        'totalProductOrderComplete': 'Total Successful Orders',
        'totalProductOrderCancel': 'Total Number of Unsuccessful Orders',
        'totalFeeOrder': 'Total Transaction Value'
    },
    'baoKimConfig': {
        'baoKimPaymentMethodType': 'Payment Method Type',
        'baoKimPaymentMethod': 'Payment Method',
        'paymentMethod': {
          'BAO_KIM_WALLET': 'Bao Kim Wallet',
          'ATM_CARD_ONLINE': 'ATM Card',
          'VISA_MASTER_CARD': 'Visa/Master Card',
          'TRANSFER': 'Transfer',
          'QR_CODE': 'QR Code',
          'VIRTUAL_ACCOUNT': 'Virtual Account'
        },
        'paymentConfirm': {
            'completed': 'Your order has been successfully paid',
            'errorPaymentConfirm': 'Your order has not yet been paid'
        }
    },
    'user': {
        'successfulActivation': 'Successful activation',
        'failedActivation': 'Activation failed, please try again later',
        'redirectLoginPage': 'Return to login page in {0} seconds',
        'resetPassword': {
          'linkNoLongerValid': 'The reset password link is no longer valid.'
        }
    },
    'socialNetwork': {
        'notiAddSocialAccountLink': 'Are you sure you want to add a google account link?',
        'googleAccountLinkExists': 'The google account is already linked to another account',
        'facebookAccountLinkExists': 'The facebook account has been linked to another account',
        'googleAccountHasBeenRegistered': 'This google account has been registered already.',
        'notiConfirmCreateAccountWithGoogle': 'Are you sure you want to create an account with this google account information?'
    }
}