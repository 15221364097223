const Layout = () => import('Views/Layout/ContentLayout')
const Home = () => import('Views/Home/Home')
const ProductList = () => import('Views/Product/List')
const ProductDetail = () => import('Views/Product/Detail')
const ProductCart = () => import('Views/Product/Cart')
const Login = () => import('Views/Account/Login')
const Register = () => import('Views/Account/Register')
const ConfirmActiveKeyView = () => import('Views/Intro/ConfirmActiveKeyView')
const Intro = () => import('Views/Intro/IntroView')
const ConfirmEmailDomain = () => import('Views/Intro/ConfirmEmailDomain')
const ConfirmActiveLink = () => import('Views/Account/ConfirmActiveLink')
const MyOrder = () => import('Views/Order/MyOrderList')
const ConfirmEntityCode = () => import('Views/Admin/ConfirmEntityCode')
const AdminLogin = () => import('Views/Admin/Login')
const ProductOrderManagement = () => import ('Views/Admin/ProductOrderManagement')
const Profile = () => import('Views/Account/Profile')
const AddShipmentDetailsInfo = () => import('Views/Product/AddShipmentDetails')
const PrivacyPolicy = () => import('Views/TernAndConditions/PrivacyPolicy')
const ComplaintsSettlementPolicy = () => import('Views/TernAndConditions/ComplaintsSettlementPolicy')
const OperatingRegulations = () => import('Views/TernAndConditions/OperatingRegulations')
const Contact = () => import('Views/Contact/Contact')
const ReportStatisticView = () => import('Views/ReportStatistic/ReportStatisticView')
const ReportStatisticLogin = () => import('Views/ReportStatistic/ReportStatisticLogin')
const OnlineOrderPaymentConfirm  = () => import('Components/OnlineOrderPaymentConfirm')
const ActivatedEmail  = () => import('Views/Account/ActivatedEmail')
const PageNotiRegistered = () => import('Views/Account/PageNotiRegistered')
const ForgotPassword = () => import('Views/Account/ForgotPassword')
const ResetPassword = () => import('Views/Account/ResetPassword')
export default [
    {
        path: '/',
        component: Layout,
        name: 'Trang chủ',
        redirect: '/home',
        children: [
            {
                path: '/home',
                name: 'Home',
                component: Home,
                meta: { isActiveLink: true }
            },
            {
                path: '/product',
                name: 'Product',
                component: ProductList,
                meta: { isActiveLink: true }
            },
            {
                path: '/product/detail/:id',
                name: 'ProductDetail',
                component: ProductDetail,
                meta: { isActiveLink: true }
            },
            {
                path: '/product/cart',
                name: 'ProductCart',
                component: ProductCart,
                meta: { isActiveLink: true }
            },
            {
                path: '/my/order',
                name: 'MyOrder',
                component: MyOrder,
                meta: { isActiveLink: true }
            },
            {
                path: '/profile',
                name: 'Profile',
                component: Profile,
                meta: { isActiveLink: true }
            },
            {
                path: '/shipment-details',
                name: 'ShipmentDetails',
                component: AddShipmentDetailsInfo,
                meta: { isActiveLink: true }
            },
            {
                path: '/privacy-policy',
                name: 'PrivacyPolicy',
                component: PrivacyPolicy
            },
            {
                path: '/complaints-settlement-policy',
                name: 'ComplaintsSettlementPolicy',
                component: ComplaintsSettlementPolicy
            },
            {
                path: '/operating-regulations',
                name: 'OperatingRegulations',
                component: OperatingRegulations
            },
            {
                path: '/contact',
                name: 'Contact',
                component: Contact,
                meta: { isActiveLink: true }
            },
            {
                path: '/online-order-payment-confirm',
                name: 'OnlineOrderPaymentConfirm',
                component: OnlineOrderPaymentConfirm,
                meta: { isActiveLink: true }
            }
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: { confirmActiveKey: true }
    },
    {
        path: '/register',
        name: 'Register',
        component: Register
    },
    {
        path: '/confirm-active-key',
        name: 'ConfirmActiveKeyView',
        component: ConfirmActiveKeyView
    },
    {
        path: '/intro',
        name: 'Intro',
        component: Intro,
        meta: { confirmActiveKey: true }
    },
    {
        path: '/confirm-email-domain',
        name: 'ConfirmEmailDomain',
        component: ConfirmEmailDomain,
        meta: { confirmEmailDomain: true }
    },
    {
        path: '/confirm-active-link/:code',
        name: 'ConfirmActiveLink',
        component: ConfirmActiveLink
    },
    {
        path: '/admin',
        name: 'ConfirmEntityCode',
        component: ConfirmEntityCode
    },
    {
        path: '/admin-login',
        name: 'AdminLogin',
        component: AdminLogin,
        meta: { requiresEntityCode: true }
    },
    {
        path: '/product-order-management',
        name: 'ProductOrderManagement',
        component: ProductOrderManagement,
        meta: { requiresAuthEntity: true }
    },
    {
        path: '/report-statistic-login',
        name: 'ReportStatisticLogin',
        component: ReportStatisticLogin,
    },
    {
        path: '/report-statistic',
        name: 'ReportStatisticView',
        component: ReportStatisticView,
        meta: { requiresReportStatistic: true }
    },
    {
        path: '/account/email_activated/:uid/:token',
        component: ActivatedEmail,
        name: 'activatedEmail'
    },
    {
        path: '/registered',
        component: PageNotiRegistered,
        name: 'PageNotiRegistered'
    },
    {
        path: '/forgot-password',
        component: ForgotPassword,
        name: 'ForgotPassword'
    },
    {
        path: '/confirm/reset/password/:uid/:token',
        component: ResetPassword,
        name: 'ResetPassword'
      }
]