import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from '@/router'
import store from '@/store'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueI18n from 'vue-i18n'
//language for Vue-I18N
import messages from './lang'
import './lib/css'
import 'bootstrap';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
// plugins/vue-google-oauth2.js
// file name can be changed to whatever you want
import GAuth from 'vue-google-oauth2'
Vue.config.productionTip = false
Vue.use(VueToast);
Vue.use(VueI18n)
//Global varible
Vue.prototype.$toastConfig = {
  duration: 2500,
  dismissible: true,
  queue: false,
  position: "bottom-right",
}
// i18n
const i18n = new VueI18n({
  locale: localStorage.locale,
  messages,
  fallbackLocale: 'vi',
})
// Bootstrap vue
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
const gauthOption = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: 'profile email',
  prompt: 'select_account'
}
Vue.use(GAuth, gauthOption)
const app = new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
export default app
