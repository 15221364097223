
import app from '@/main'

const state = {
  selectedLocale: 'en'
}

// getters
const getters = {
  LOCALE_DATA: state => {
    return state.selectedLocale
  }
}

// actions
const actions = {
  CHANGE_LOCALE (context, payload) {
    context.commit('CHANGE_LOCALE_SUCCESS', payload)
  }
}

// mutations
const mutations = {
  /**
    * method for setting language state
   */
  CHANGE_LOCALE_SUCCESS (state, payload) {
    app.$i18n.locale = payload
    localStorage.setItem("locale",payload)
    state.selectedLocale = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
