import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'
import { currentEntityId } from 'Utils/functionUtils'

// state
const state = {
}

// getters
const getters = {
}

// actions
const actions = {
    GET_PAYMENT_METHODS_LIST: (context, payload) => {
        return new Promise((resolve, reject) => {
            commonRequest.getRequest(
                `/api/v1/portal/${currentEntityId()}/payment/methods/get_payment_methods_list_on_portal/`,
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    GET_BANK_TRANSFER_LIST: (context, payload) => {
        return new Promise((resolve, reject) => {
            commonRequest.getRequest(
                `/api/v1/portal/${currentEntityId()}/payment/methods/get_bank_transfer_list_on_portal/`,
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                }
            )
        })
    },
    REQUEST_MOMO_PAYURL: (context, payload) => {
        return new Promise((resolve, reject) => {
            commonRequest.postRequest(
                `/api/v1/payments/online_payment/request_momo_payurl/`,
                payload,
                function (res) {
                    resolve(res)
                },
                function (error) {
                    reject(error)
                },
                60000
            )
        })
    },
}

// mutations
const mutations = {
}

export default {
    state,
    getters,
    actions,
    mutations
}
