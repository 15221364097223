import { Promise } from "es6-promise";
import commonRequest from "Api/index";

// state
const state = {
  reportStatisticList: [],
};

// getters
const getters = {
  GET_REPORT_STATISTIC_DATA: (state) => {
    return state.reportStatisticList;
  },
};

// actions
const actions = {
  GET_REPORT_STATISTIC: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/report_statistic/get_report_statistic/`,
        payload,
        function (res) {
          context.commit("GET_REPORT_STATISTIC", res.data);
          resolve(res);
        },
        function (error) {
          reject(error);
        }
      );
    });
  },
};

// mutations
const mutations = {
  GET_REPORT_STATISTIC: (state, payload) => {
    state.reportStatisticList = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
