import { Promise } from 'es6-promise'
import commonRequest from 'Api/index'
import functionUtils, { currentEntityId } from 'Utils/functionUtils'
import {
  isCookieEnabled,
  removeCookie
} from 'tiny-cookie'
// state
const state = {
  currentAccountData: null,
  shipmentDetails: { id: null }
}

// getters
const getters = {
  CURRENT_ACCOUNT_DATA: (state) => {
    return state.currentAccountData
  },
  SHIPMENT_DETAILS_DATA: (state) => {
    return state.shipmentDetails
  }
}

// actions
const actions = {
  LOGIN: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/user/login/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  LOGOUT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        'api/v1/auth/jwt-refresh/', { token: payload },
        function (res) {
          context.commit('LOGOUT', res.data)
          if (isCookieEnabled()) {
            removeCookie('token')
            removeCookie('userId')
            removeCookie('isActiveLink')
            sessionStorage.removeItem('entityId')
            sessionStorage.removeItem('promotionSession')
            resolve(res)
          } else {
            let error = ''
            reject(error)
          }
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  REGISTER: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/user/register/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  VERIFY_EMAIL_DOMAIN: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/${currentEntityId()}/authen/verify_email_domain/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CONFIRM_ACTIVE_LINK: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequestNoToken(
        `/api/v1/portal/user/confirm_active_link/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  CHECK_USER_IS_ACTIVATED: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/${currentEntityId()}/authen/${payload.id}/check_user_is_activated/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_USER_PROFILE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/user/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_USER_PROFILE: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `/api/v1/portal/user/${payload.id}/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  UPDATE_USER_PASSWORD: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.putRequest(
        `/api/v1/portal/user/${payload.id}/update_password/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_CURRENT_ACCOUNT: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/user/${payload.id}/`,
        payload,
        function (res) {
          context.commit('GET_CURRENT_ACCOUNT_HANDLER', res.data)
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  SET_SHIPMENT_DETAILS: (context, payload) => {
    context.commit('SET_SHIPMENT_DETAILS_HANDLER', payload)
  },
  CREATE_DELIVERY_INFO: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/delivery/info/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  GET_DELIVERY_INFO_DETAIL: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.getRequest(
        `/api/v1/portal/delivery/info/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  // Report Statistic
  REPORT_LOGIN_STATISTIC: (context, payload) => {
    return new Promise((resolve, reject) => {
      commonRequest.postRequest(
        `/api/v1/portal/report-statistic-login/`,
        payload,
        function (res) {
          resolve(res)
        },
        function (error) {
          reject(error)
        }
      )
    })
  },
  ACTIVATE_ACCOUNT: (context, payload) => {
    return new Promise((resolve, reject) => {
        commonRequest.postRequest(
            `api/v1/accounts/activated/`,
            payload,
            function (res) {
                resolve(res)
            },
            function (error) {
                reject(error)
            }
        )
    })
  },
  RESEND_ACTIVATE_ACCOUNT: (context, payload) => {
    return new Promise((resolve, reject) => {
        commonRequest.postRequest(
            `/api/v1/portal/user/resend_email_active/`,
            payload,
            function (res) {
                resolve(res)
            },
            function (error) {
                reject(error)
            }
        )
    })
  },
  SEND_EMAIL_FORGOT_PASSWORD: (context, payload) => {
    return new Promise((resolve, reject) => {
        commonRequest.postRequest(
          `api/v1/forgot_password/send_email_reset_password/`,
          payload,
          function (res) {
            resolve(res)
          },
          function (error) {
            reject(error)
          }
        )
    })
  },
  CONFIRM_RESET_PASSWORD: (context, payload) => {
    return new Promise((resolve, reject) => {
        commonRequest.postRequest(
          `api/v1/auth/confirm-reset-password/`,
          payload,
          function (res) {
            resolve(res)
          },
          function (error) {
            reject(error)
          }
        )
    })
  }
}

// mutations
const mutations = {
  GET_CURRENT_ACCOUNT_HANDLER: (state, payload) => {
    state.currentAccountData = payload
    state.shipmentDetails = {
      id: null,
      'fullname': functionUtils.concatFullname(payload.last_name, payload.first_name),
      'phone': payload.phone,
      'email': payload.email
    }
  },
  SET_SHIPMENT_DETAILS_HANDLER: (state, payload) => {
    state.shipmentDetails = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
