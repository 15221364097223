/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Router from 'vue-router'

// Routes
import paths from './paths'
import { isCookieEnabled, getCookie } from 'tiny-cookie'
Vue.use(Router)
// Create a new router
const router = new Router({
    base: process.env.VUE_APP_BASE_PATH,
    mode: 'history',
    routes: paths
})
router.beforeEach((to, from, next) => {
    // requires Report Statistic
    const loadReportStatisticSessionFromCookie = () => {
        const token = isCookieEnabled() ? getCookie('report_statistic_token') : null
        if (token) {
            return true
        } else {
            return false
        }
    }
    const isReportStatisticLogged = () => loadReportStatisticSessionFromCookie()
    if (to.meta.requiresReportStatistic) {
        if (!isReportStatisticLogged()) {
            next({
                path: '/report-statistic-login'
            })
        } else {
            next()
        }
    } else {
        // 
        const isLogged = () => loadSessionFromCookie()
        const isActiveLink = () => loadActivateFromCookie()
        const loadSessionFromCookie = () => {
            const token = isCookieEnabled() ? getCookie('token') : null
            if (token) {
                return true
            } else {
                return false
            }
        }
        const loadActivateFromCookie = () => {
            const activate = isCookieEnabled() ? getCookie('isActiveLink') : null
            if (activate) {
                return true
            } else {
                return false
            }
        }
        let entityId = sessionStorage.getItem('entityId')
        if (to.meta.requiresAuthEntity) {
            if (!isLogged()) {
                next({
                    path: '/admin-login'
                })
            } else {
                next()
            }
        }
        if (to.meta.requiresEntityCode) {
            if (!entityId) {
                next({
                    path: '/admin'
                })
            } else {
                next()
            }
        }
        if (to.meta.confirmActiveKey) {
            if (!entityId) {
                next({
                    path: '/confirm-active-key'
                })
            } else {
                next()
            }
        }
        if (to.meta.confirmEmailDomain) {
            if (!entityId) {
                next({
                    path: '/confirm-active-key'
                })
            } else if (!isLogged()) {
                next({
                    path: '/intro'
                })
            } else {
                next()
            }
        }
        if (to.meta.requiresAuth) {
            if (!entityId) {
                next({
                    path: '/confirm-active-key'
                })
            } else if (!isLogged()) {
                next({
                    path: '/intro'
                })
            } else {
                next()
            }
        }
        if (to.meta.isActiveLink) {
            if (!entityId) {
                next({
                    path: '/confirm-active-key'
                })
            } else if (!isLogged()) {
                next({
                    path: '/intro'
                })
            } else if (!isActiveLink()) {
                next({
                    path: '/confirm-email-domain'
                })
            } else {
                next()
            }
        }
    }
    next()
})
export default router